.frontpage {
	main {
		.box {
			padding-top: 40px;
			padding-bottom: 40px;

			@media (max-width:@screen-xs-max) {
				padding-top: 25px;
			}

			&:first-child {
				@media (min-width:@screen-md-min) {
					padding-top:58px;
				}
				@media (max-width:@screen-sm-max) and (min-width:@screen-sm-min) {
					padding-top: 97px;
				}
				@media (max-width:@screen-xs-max) {
					padding-top: 32px;
				}
			}
			&:last-child {
				padding-bottom:0;
			}

			&.full {
				padding-top:0;
				padding-bottom:0;

				>.contentWrap {
					max-width:none;
					padding-right:0;
					padding-left:0;
				}
			}

			> .contentWrap {
				@media print {
					padding-right:0;
					padding-left:0;
				}
			}

			.boxTitle {
				h2 {
					margin-bottom: 30px;
					font-size: 40px;
					color:@color-first;
					text-align: center;

					@media (max-width:@screen-xs-max) {
						margin-bottom: 20px;
						font-size: 30px;
					}
				}
			}
		}
	}
}
