.promo.items {
	.make-row();
	display:flex;
	flex-wrap:wrap;

	@media (min-width:1560px) {
		.make-row(74px);
	}

	.item {
		.make-xs-column(12);
		.make-md-column(4);
		margin-bottom: 60px;

		@media (min-width:1560px) {
			padding-left:37px;
			padding-right:37px;
		}

		.box_extras_articles & {
			@media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) {
				margin-bottom: 84px;
			}
			@media (max-width:@screen-xs-max) {
				margin-bottom:50px;
			}
		}
		#custom-promo & {
			@media (max-width: @screen-sm-max) {
				margin-bottom:50px;
			}
		}

		.vendorView & {
			@media (min-width:@screen-md-min) {
				margin-bottom: 37px;
			}
		}

		.wrap {
			position: relative;

			@media (max-width:@screen-sm-max) and (min-width:@screen-sm-min) {
				.clearfix();
			}
		}

		.image {
			@media (max-width:@screen-sm-max) and (min-width:@screen-sm-min), print {
				float: left;
				width: 38%;
				padding-left: 10px;
			}

			img {
				width:100%;

				#custom-promo & {
					@media (max-width:@screen-sm-max) {
						height: 19vw;
						object-fit: cover;
					}
					@media only screen and (max-width:@screen-xs-max) {
						height: 46vw;
					}
				}
			}
		}

		.content {
			@media (max-width:@screen-sm-max) and (min-width:@screen-sm-min), print {
				float: left;
				width: 60%;
				padding-left: 30px;
			}
		}

		h2 {
			font-family:@font-family-condensed;
			font-size: 36px;
			font-weight: 600;
			line-height: 1.25;;
			letter-spacing: -0.3px;
			margin: 35px 0 7px;

			@media (max-width: @screen-xs-max) {
				font-size: 26px;
			}
			@media print {
				margin-top:10px;
			}

			.box_extras_articles & {
				@media (min-width:@screen-md-min) {
					margin-top:50px;
				}
				@media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) {
					margin-top:14px;
				}
				@media (max-width: @screen-xs-max) {
					margin-top:23px;
				}
			}

			#custom-promo & {
				@media (max-width: @screen-sm-max) and (min-width: @screen-sm-min) {
					font-size:26px;
					margin-top:0;
				}
			}

			a {
				display:block;
				color: @color-black;

				&:hover {
					color:@color-first;
				}

				&:after {
					content:'';
					position: absolute;
					top:0;
					bottom:0;
					left:0;
					right:0;
				}
			}
		}

		.intro {
			line-height:1.4;
			font-weight:300;
			color: @color-black;

			@media (max-width:@screen-xs-max) {
				font-size: 18px;
				line-height: 1.4445;
			}

			i {
				position: relative;

				&:before {
					position: absolute;
					bottom: -5px;
					color:@color-first;
				}
			}
		}

		.jip {
			position: absolute;
			top: 0;
			right: 0;
		}
	}
}
