@media (min-width:@screen-sm-min) {
	.mceAlignRight {
		.pull-right;
		clear: right;
		margin: 0 0 @line-height-computed @grid-gutter-width;
	}
	.mceAlignLeft {
		.pull-left;
		clear: left;
		margin: 0 @grid-gutter-width @line-height-computed 0;
	}
}



.mceAlignRight,
.mceAlignLeft {
	@media (min-width:@screen-sm-min) {
		max-width:50%;
	}
	@media (max-width:@screen-xs-max) {
		display:block;
		margin: 0 0 40px;
	}
}
