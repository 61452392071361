aside {
	flex: 1 0 auto;
	display: flex;
	justify-content: flex-end;

	> .wrap {
		float: right;
		width: 100%;
		max-width: 251px;
		border-left: 1px solid @color-border;
		padding-left: 30px;
	}

	.boxTitle {
		h2 {
			padding-bottom: 20px;
			margin-bottom: 20px;
			font-weight: 700;
			color: @color-first;
			border-bottom: 1px solid @color-first;
		}
	}

	.box_sitemap {
		font-size:18px;

		.mmLevel2 {
			border-top:1px solid @color-border;
		}

		.level2 {
			border-bottom:1px solid @color-border;

			> a {
				padding: 3px 0 4px;
			}
		}

		.selectedLevel2 > a {
			position: relative;

			&:before {
				content:'';
				position: absolute;
				top: 15px;
				left: -36px;
				width:11px;
				height:11px;
				background:@color-first;
				border-radius:50%;
			}
		}

		a {
			display:block;
			color:@color-text-dark;

			&:hover {
				color:@color-first;
			}
		}

		li[class*="selectedLevel"] > a {
			color:@color-first;
			font-weight:bold;
		}
	}
}
