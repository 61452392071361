.vendorView {
	#custom-banner {
		@media (min-width:@screen-md-min) {
			margin-top: 86px;
			margin-bottom: 43px;
		}
	}

	#store {
		.boxText {
			> .description {
				text-align: center;
				margin-bottom:30px;
			}
		}

		.menu.list {
			@media (min-width:1560px) {
				.make-row(74px);
			}

			> li {
				.make-xs-column(12);
				.make-sm-column(6);
				.make-md-column(4);
				max-width:515px;

				@media (min-width:1560px) {
					padding-left:37px;
					padding-right:37px;
					margin-bottom:60px;
				}
			}
		}
	}
}
