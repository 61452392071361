.button,
input.submit,
button.save_design,
input[type="submit"] {
	&:extend(.btn);
	&:extend(.btn-primary);

	&:hover {
		&:extend(.btn:hover);
		&:extend(.btn-primary:hover);
	}
}


input.cancel,
button.cancel,
button.delete,
button#cancel {
	&:extend(.btn);
	&:extend(.btn-default);

	&:hover {
		&:extend(.btn:hover);
		&:extend(.btn-default:hover);
	}
}

.btn {
	white-space: inherit;
	transition: background .4s ease, color .4s ease;

	&.btn-primary {

	}
}
