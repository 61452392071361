@media (min-width:@screen-md-min) {
	#mainmenu {
		.mmLevel1 {
			.level1 {
				position:relative;

				&:not(.open) {
					>.mmLevel2 {
						max-height:0!important;
						pointer-events: none;

						.tabbing & {
							display:none;
						}
					}
				}

				&:last-child {
					.mmLevel2 {
						right:10px;
						left:auto;
					}
				}

				> a {
					position: relative;

					&:before, &:after {
						content: '';
						position: absolute;
						left: 50%;
						width: 0;
						height: 0;
						pointer-events: none;
						opacity: 0;
						transition: opacity .1s ease;
						transform: translateX(-50%);
					}
					&:before {
						bottom: 0;
						border-right: 9px solid transparent;
						border-bottom: 17px solid #d7d7d7;
						border-left: 9px solid transparent;
					}

					&:after {
						z-index: 999;
						bottom: -1px;
						border-right: 8px solid transparent;
						border-bottom: 16px solid @color-white;
						border-left: 8px solid transparent;
						transition: opacity .1s ease;
					}
				}

				.mmLevel2 {
					position:absolute;
					z-index: 99;
					top: 100%;
					left: -33%;
					display:block;
					min-width: 270px;
					line-height:normal;
					text-align:left;
					background: #fff;
					opacity: 0;
					box-shadow: 0px 7px 6px 0px rgba(0, 0, 0, 0.26);
					transition: max-height .4s ease, box-shadow .4s ease, opacity .4s ease;

					@media (max-width: @screen-sm-max) {
						display: none;
					}

					li {
						&:first-of-type {
							position: relative;

							a {
								padding-top:17px;
								border-top:1px solid @dropdown-border;
							}
						}
						&:last-of-type {
							a {
								padding-bottom: 27px;
								border-bottom:1px solid @dropdown-border;
							}
						}
						a {
							display:block;
							padding: 0 27px;
							font-size: 18px;
							font-weight: 300;
							color:@dropdown-link-color;
							white-space: nowrap;
							border-right: 1px solid @dropdown-border;
							border-left: 1px solid @dropdown-border;
							transition: color .4s ease;

							&:hover {
								color: @dropdown-link-hover-color;
							}
							span {
								display: block;
								padding: 7px 0 8px;
							}
						}

						&.selectedLevel2 a {
							position: relative;
							font-weight: 700;
							color: @dropdown-link-active-color;

							&:before {
								content:'';
								position: absolute;
								top:0;
								bottom:0;
								left:0;
								width:20px;
								transform: rotate(90deg) translate3d(-2px,7px,0px);
							}
						}
						ul {
							display:none;
						}
					}
				}
				&.open {
					> a {
						&:before, &:after {
							opacity: 1;
						}
					}

					.mmLevel2 {
						opacity: 1;
					}
				}
			}
		}
	}
}
