#custom-banner {
	position: relative;
	padding-top: 23px;

	@media (max-width:@screen-xs-max) {
		padding-top: 0;
	}

	.contentWrap {
		@media (max-width:@screen-xs-max) {
			padding-left:0;
			padding-right:0;
		}
	}

	.item {
		position: relative;

		&:hover {
			.content .fas {
				transform: translateX(5px);
			}
		}

		.image {
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;

			@media (min-width:@screen-sm-min) {
				padding-bottom:percentage(500/1470);
			}
			@media (max-width:@screen-xs-max) {
				padding-bottom:34%;
			}
			@media (max-width:@screen-xxs-max) {
				padding-bottom:100%;
			}
		}

		.text {
			font-weight:300;
			color: @color-white;
			line-height: 1.3;
			background:fade(@color-black,70%);
			.hidden-sm;

			@media (min-width:@screen-md-min) {
				position: absolute;
				bottom: 34px;
				right: 36px;
				width: 100%;
				max-width: 566px;
				border-radius:5px;
				padding: 20px 34px 33px;
			}
			@media (max-width:@screen-xs-max) {
				font-size: 18px;
				line-height: 1.4445;
				padding: 35px 18px 44px;
			}

			h2 {
				font-family:@font-family-condensed;
				font-size: 36px;
				font-weight: 600;
				color: @color-white;
				letter-spacing: -0.3px;
				margin-bottom:5px;

				@media (max-width:@screen-xs-max) {
					font-size:24px;
				}
			}

			p {
				&:last-of-type {
					display:inline;
				}
			}

			i {
				transition:transform .4s ease;
				color:@color-first;
			}
		}

		.more {
			position: absolute;
			left: 0;
			right: 0;
			top:0;
			bottom: 0;
			color:@color-black;
		}
	}
}
