@color-alphafilter-a: #1e5993;
@font-size-empfilters:16px;
@height-empfilters:40px;
#employees {
	.filterControls {
		@media (min-width: @screen-sm-min) {
			margin-bottom: 27px;
		}
		form {
			display: flex;
			align-items: center;

			@media (max-width: 1110px) {
				flex-direction: column;
				align-items: flex-start;
			}
		}
		.searchFilter,
		.selectFilter {
			width: 250px;
			margin-right: 30px;

			@media (max-width: 1110px) {
				margin-bottom: 20px;
			}

			label {
				&:extend(.sr-only);
			}
		}

		input,
		select {
			border-radius: 50px;
			font-size: @font-size-empfilters;
			box-shadow:none;
			border-color:#d7d7d7;
			padding: 0 25px;
			width: 100%;
			min-width: 0;
			min-height: 0;
			height: @height-empfilters;
		}

		.selectFilter {
			position: relative;
			.removeSelectArrow;

			.lmnt {
				display: block;
				position: inherit;

				.fa-before(@fa-var-caret-down);
				&:before {
					position: absolute;
					right:25px;
					top:50%;
					font-size: 21px;
					color:@color-first;
					pointer-events: none;
					transform: translateY(-50%);
				}
			}

			select {
				font-size: @font-size-empfilters;
			}
		}

		.alphaFilter {
			.hidden-xs;
			font-size: @font-size-empfilters;
			clear: both;
			display: flex;
			justify-content: space-evenly;
			flex: 1;
			
			@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
				width: 100%;
			}

			> * {
				display: inline-block;
				padding: 6px 4px 6px;
				line-height: 1;
			}

			> span {
				color:@color-text-light;
			}

			a {
				color:@color-alphafilter-a;
				transition:color .4s ease, background .4s ease;

				&:hover {
					color:@color-first;
				}

				&.selected {
					background:@color-first;
					color:@color-white;
				}
			}
		}
	}
}
