html {
	height:101%;
}

body {
	display: flex;
	flex-direction: column;
	min-width:305px;
	min-height: 100vh;
	-webkit-font-smoothing:antialiased;
	-webkit-box-orient: vertical;

	&#tinymce, &.mceContentBody {
		display:block;
		flex-direction: initial;
	}
}

.mm-page {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	-webkit-box-orient: vertical;
}

#page {
	flex: 1 0 auto;

	//IE fix
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		min-height:92vh;
	}
}

#page {
	position:relative;

	@media (min-width:@screen-md-min) {
		margin-bottom: -148px;
		z-index: 1;
	}

	.frontpage & {
		@media (min-width:@screen-md-min) {
			margin-bottom: -188px;
		}
	}
	// Move content down so the mobile header does not block it
	@media only screen and (max-width:@screen-sm-max) {
		padding-top:@header-height-mobile;
	}
}

#contentContainer {
	padding-bottom: 26px;

	body:not(.frontpage) & {
		@media only screen and (max-width:@screen-sm-max) {
			padding-top: 8px;
		}
		@media only screen and (max-width:@screen-xs-max) {
			padding-top: 15px;
		}
	}

	> .contentWrap {
		.clearfix();

		@media only screen and (max-width:@screen-sm-max) and (min-width:@screen-sm-min) {
			body:not(.frontpage) & {
				padding-left: 48px;
				padding-right: 48px;
			}
		}

		.frontpage & {
			max-width:none;
		}

		> .row {
			display: flex;
			flex-wrap: wrap;
		}
	}
}

main {
	.make-md-column(9);

	body.hideright & {
		.make-md-column(12);
	}
	body.frontpage & {
		.make-md-column(12,0);
	}

	@media print {
		padding-left:0;
		padding-right:0;
	}
}

aside {
	.make-md-column(3);
	.hidden-sm;
	.hidden-xs;
	.noprint;

	body.hideright &,
	body.frontpage & {
		&:extend(.hidden);
	}
}
