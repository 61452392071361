@import "employeesFilter";
#employees {
	.employees__list {
		font-size: 18px;
		&:not(:last-child) {
			margin-bottom: 20px;
		}
		.employee__row {
			@media (max-width: 1544px) {
				transition: padding .4s ease;
			}
			&:first-child {
				border-top: 1px solid @color-border;
			}
			&:not(:first-child) {
				border-top: 1px solid @color-border;
				border-bottom: 1px solid @color-border;
			}
			&:not(.isOpen) {
				.employee__content {
					max-height: 0!important;
					overflow: hidden;
					opacity: 0;
				}
			}
			&.isOpen {
				border: 1px solid @color-second;
				@media (min-width: 1545px) {
					margin-left: -20px;
					margin-right: -20px;
					padding-right: 20px;
					padding-left: 20px;
				}
				@media (max-width: 1544px) {
					padding-left: 20px;
					padding-right: 20px;
				}

				box-shadow: 0 0 53px -9px rgba(0, 0, 0, 0.3);
				.employee__toolbar {
					border-bottom: 0;
				}
			}
			&:hover, &.isOpen {
				.employee__toolbar__name {
					font-weight: 600;
					color: @color-second;
				}
			}
			.employee__toolbar {
				display: flex;
				flex-wrap: wrap;
				padding: 8px 0;
				&:hover {
					cursor: pointer;
				}
				> * {
					&.employee__toolbar__name {
						flex: 0 0 30%;
					}
					&.employee__toolbar__jobtitle {
						flex: 0 0 36%;
					}
					&.employee__toolbar__phone {
						.hidden-xs();
						flex: 0 0 17%;
					}
					&.employee__toolbar__email {
						.hidden-xs();
						flex: 0 0 17%;
					}
					&:last-child {
						text-align: right;
					}
					@media (min-width: @screen-sm-min) and (max-width: 1300px) {
						flex: 0 0 50%!important;
						&.employee__toolbar__name {
							order: 1;
						}
						&.employee__toolbar__jobtitle {
							order: 3;
						}
						&.employee__toolbar__phone {
							order: 2;
							text-align: right;
						}
						&.employee__toolbar__email {
							order: 4;
						}
					}
					@media (max-width: @screen-xs-max) {
						flex: 0 0 100%!important;
					}
				}
				.employee__toolbar__name {

				}
				.employee__toolbar__jobtitle {

				}
				.employee__toolbar__phone {

				}
				.employee__toolbar__email {

				}
			}
			.employee__content {
				transition: max-height .4s ease, opacity .4s ease .4s, border .4s ease, box-shadow .4s ease;
				.employee__content__inner {
					@media (min-width: @screen-sm-min) {
						display: flex;
					}
					padding: 20px 0;
					.employee__content__image {
						@media (min-width: @screen-sm-min) {
							margin-right: 40px;
							flex: 0 0 13%;
						}
						@media (max-width: @screen-xs-max) {
							margin-bottom: 20px;
						}
						.img {
							padding-bottom: 100%;
							background-size: cover;
							background-repeat: no-repeat;
							background-position: center center;
						}
					}
					.employee__content__description {
						line-height: 1.6;
						.employee__content__extra {
							display: flex;
							flex-wrap: wrap;
							@media (max-width: @screen-xs-max) {
								margin-bottom: 1px;
							}
							> div {
								a {
									&:before {
										margin-right: 10px;
									}
								}
								&:not(:last-child) {
									&:after {
										content: '|';
										display: inline-block;
										margin: 0 10px;
									}
								}
							}
							.employee__content__phone {
								a {
									&:before {
										.fas();
										content: @fa-var-phone;
										transform: rotate(90deg);
									}
								}
							}
							.employee__content__mobile {
								a {
									&:before {
										.fas();
										content: @fa-var-mobile;
									}
								}
							}
							.employee__content__email {
								a {
									&:before {
										.fas();
										content: @fa-var-envelope;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}