.product.view {
	color:@color-black;

	.boxTitle {
		.make-xs-column(12);
		.make-md-column(6);
		.make-md-column-push(6);

		@media (min-width:@screen-md-min) {
			padding-left: 45px;
		}
	}

	.main {
		.clearfix();
		.make-xs-column(12);

		@media (max-width:@screen-xs-max) {
			padding:0;
		}

		.inner {
			h1 {
				font-weight: 500;
				font-size: 24px;
				margin-bottom: 8px;
			}
		}
	}
	.side {
		.clearfix();
		.make-md-column(6);
		clear:both;

		@media (min-width:@screen-md-min) {
			margin-top: -97px;
		}

		> * {
			@media (min-width:@screen-md-min) {
				max-width: 595px;
				float: right;
				width: 100%;
			}
		}

		.images {
			margin-bottom:93px;

			@media (max-width:@screen-sm-max) {
				margin-bottom:30px;
			}
		}

		.image {
			position: relative;

			@media (min-width:@screen-md-min) {
				padding-bottom: 100%;
				margin: 0 auto 60px;
				text-align: center;
			}

			a {
				@media (min-width:@screen-md-min) {
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				img {
					max-height: 100%;

					@media (max-width:@screen-sm-max) and (min-width:@screen-sm-min) {
						max-width: 60%;
					}
				}
			}

			.caption {
				display: none;
			}
		}

		.albumImages {
			clear:both;
			list-style: none inside none;
			margin: 36px 0 0;
			padding:0;
			.clearfix();

			.owl-nav {
				button {
					position:absolute;
					top: 36%;

					@media (min-width:@screen-sm-min) {
						display:none;
					}

					&.disabled {
						opacity:.5;
					}

					&:before {
						font-size:26px;
						color:@color-first;
					}
				}
			}

			.owl-prev {
				left: -5px;

				&:before {
					.fas();
					content:@fa-var-arrow-alt-circle-left;
				}
			}
			.owl-next {
				right: -5px;

				&:before {
					.fas();
					content:@fa-var-arrow-alt-circle-right;
				}
			}
		}

		&+.main {
			.make-md-column(6);

			@media (min-width:@screen-lg-min) {
				padding-left: 45px;
				max-width: 625px;
			}
			@media (min-width:@screen-md-min) {
				margin-top: 8px;
			}
		}
	}

	h2 {
		font-weight: normal;
		color: @color-black;
		letter-spacing: -0.2px;
		margin-bottom: 30px;
	}

	.introDesc {
		@media (max-width:@screen-xs-max) {
			white-space: nowrap;
			overflow-x: auto;
			max-width: calc(100vw - 30px);
			margin-bottom: 60px;
		}

		table {
			width: 100%;
			font-weight: 300;

			tr {
				&:nth-child(even) {
					background:#ebebeb;
				}

				&:first-child >*:last-child {
					@media (min-width:@screen-md-min) {
						white-space: nowrap;
					}
				}

				>* {
					padding-right:20px;

					&:first-child {
						padding-left:20px;
					}
					&:last-child {
						text-align:right;
					}
					&:first-of-type:nth-last-of-type(2) {
						padding-right:0;
					}
				}
			}
		}
	}

	#infoTable {
		border:1px solid @color-border;
		padding:26px 25px 39px;
		margin-bottom: 76px;
		letter-spacing: -0.2px;

		@media (min-width:@screen-lg-min) {
			font-size:22px;
		}
		@media (max-width:@screen-md-max) {
			padding-top:34px;
		}
		@media (max-width:@screen-sm-max) {
			font-size:16px;
			margin-bottom: 30px;
		}

		table,
		tbody,
		tr,
		th, td {
			@media (max-width:@screen-xs-max) {
				display:block;
			}
		}

		table {
			width:100%;

			tr {
				@media (max-width:@screen-xs-max) {
					margin-bottom:15px;
				}

				> * {
					padding: 7px 10px 7px 80px;

					@media (max-width:@screen-xs-max) {
						padding-top:0;
						padding-bottom:0;
					}
				}

				&.reference th:before {
					background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27' height='27' viewBox='0 0 27 27'%3E%3Cpath fill='%2300483a' d='M26 17h-7v-7h7c.6 0 1-.4 1-1s-.4-1-1-1h-7V1c0-.6-.4-1-1-1s-1 .4-1 1v7h-7V1c0-.6-.4-1-1-1S8 .4 8 1v7H1c-.6 0-1 .4-1 1s.4 1 1 1h7v7H1c-.6 0-1 .4-1 1s.4 1 1 1h7v7c0 .6.4 1 1 1s1-.4 1-1v-7h7v7c0 .6.4 1 1 1s1-.4 1-1v-7h7c.6 0 1-.4 1-1s-.4-1-1-1zm-16 0v-7h7v7h-7z'/%3E%3C/svg%3E");
				}
				&.storageTime th:before {
					background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27' height='27' viewBox='0 0 27 27'%3E%3Cpath fill='%2300483a' d='M26 27c-.6 0-1-.4-1-1V1c0-.6.4-1 1-1s1 .4 1 1v25c0 .6-.4 1-1 1zM20.7 12.7L8.7.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l11.3 11.8L7.3 25.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l11.9-12.4c.2-.2.3-.5.3-.8.1-.3 0-.5-.2-.8z'/%3E%3Cpath fill='%2300483a' d='M13.7 12.7L1.7.3C1.3-.1.7-.1.3.3c-.4.4-.4 1 0 1.4l11.3 11.8L.3 25.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l12-12.4c.2-.2.3-.5.3-.8 0-.3-.1-.5-.3-.8z'/%3E%3C/svg%3E");
				}
				&.unit th:before {
					background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27' height='28' viewBox='0 0 27 28'%3E%3Cpath fill='%2300483a' d='M11 12H1c-.6 0-1-.4-1-1V1c0-.6.4-1 1-1h10c.6 0 1 .4 1 1v10c0 .6-.4 1-1 1zm-9-2h8V2H2v8zM26 12H16c-.6 0-1-.4-1-1V1c0-.6.4-1 1-1h10c.6 0 1 .4 1 1v10c0 .6-.4 1-1 1zm-9-2h8V2h-8v8zM11 28H1c-.6 0-1-.4-1-1V17c0-.6.4-1 1-1h10c.6 0 1 .4 1 1v10c0 .6-.4 1-1 1zm-9-2h8v-8H2v8zM26 28H16c-.6 0-1-.4-1-1V17c0-.6.4-1 1-1h10c.6 0 1 .4 1 1v10c0 .6-.4 1-1 1zm-9-2h8v-8h-8v8z'/%3E%3C/svg%3E");
				}
				&.weight th:before {
					background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='26.7' viewBox='0 0 28 26.7'%3E%3Cpath fill='%2300483a' d='M27.7 11.9l-.6-.6c-.2-.2-.4-.3-.7-.3h-2.3c-1.3 0-2.4-1.1-2.4-2.5 0-.5.2-1.1.5-1.5.6-.9.9-1.7.9-2.5 0-2.4-2-4.4-4.4-4.4-2.4 0-4.4 2-4.4 4.4 0 .9.3 1.8.9 2.6.2.3.5.8.5 1.5 0 1.2-.8 2.2-1.9 2.4.2-.5.3-1 .3-1.5 0-2.4-2-4.4-4.4-4.4C7.2 5 5.2 7 5.2 9.4c0 .9.3 1.8.9 2.6.2.3.5.8.5 1.5 0 1.4-1.1 2.5-2.4 2.5H1.6c-.3 0-.5.1-.7.3l-.6.6c-.2.2-.3.5-.3.8v8c0 .6.4 1 1 1h26c.6 0 1-.4 1-1v-13c0-.3-.1-.6-.3-.8zM2 18.1h2.2c2.4 0 4.4-2 4.4-4.5 0-.9-.3-1.8-.9-2.6-.2-.5-.5-1-.5-1.6C7.2 8.1 8.3 7 9.6 7 10.9 7 12 8.1 12 9.4c0 .4-.2.9-.5 1.4-.4.6-.9 1.6-.9 2.7 0 2.5 2 4.5 4.4 4.5h2v6.7H2v-6.6zm17 6.6v-7c0-.3-.1-.5-.3-.7l-.6-.6c-.2-.2-.4-.3-.7-.3h-2.3c-1.3 0-2.4-1.1-2.4-2.5 0-.1 0-.3.1-.5h.5c2.4 0 4.4-2 4.4-4.5 0-.9-.3-1.8-.9-2.6-.2-.3-.5-.8-.5-1.4 0-1.3 1.1-2.4 2.4-2.4 1.3 0 2.4 1.1 2.4 2.4 0 .4-.2.9-.5 1.4-.4.6-.9 1.6-.9 2.7 0 2.5 2 4.5 4.4 4.5H26v11.7h-7z'/%3E%3C/svg%3E");
				}
				&ean th:before {
					background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26.6' height='27' viewBox='0 0 26.6 27'%3E%3Cpath fill='%2300483a' d='M1 27c-.6 0-1-.4-1-1V1c0-.6.4-1 1-1s1 .4 1 1v25c0 .6-.4 1-1 1zM4.6 27c-.6 0-1-.4-1-1V1c0-.6.4-1 1-1s1 .4 1 1v25c0 .6-.4 1-1 1zM18.6 27c-.6 0-1-.4-1-1V1c0-.6.4-1 1-1s1 .4 1 1v25c0 .6-.4 1-1 1zM25.6 27c-.6 0-1-.4-1-1V1c0-.6.4-1 1-1s1 .4 1 1v25c0 .6-.4 1-1 1z'/%3E%3Cpath fill='%23006837' d='M8.6 1h6v25h-6z'/%3E%3Cpath fill='%2300483a' d='M14.6 27h-6c-.6 0-1-.4-1-1V1c0-.6.4-1 1-1h6c.6 0 1 .4 1 1v25c0 .6-.4 1-1 1zm-5-2h4V2h-4v23z'/%3E%3C/svg%3E");
				}

				&.file {
					th:before {
						top: 14px;
						background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26.5' height='27' viewBox='0 0 26.5 27'%3E%3Cpath fill='%2300483a' d='M26.3 6.9L19.7.3c-.2-.2-.5-.3-.8-.3H6.5c-.6 0-1 .4-1 1v.7l-4.6.6c-.3 0-.5.2-.7.4-.2.2-.2.4-.2.7l2.8 22.7c.1.5.5.9 1 .9h.1l2-.2c.2.1.4.2.6.2h19c.6 0 1-.4 1-1V7.6c0-.3-.1-.5-.2-.7zm-3.2.1h-3.6V3.4L23.1 7zm-21-2.9l3.4-.4v21.1l-.9.1L2.1 4.1zM7.5 25V2h10v6c0 .6.4 1 1 1h6v16h-17z'/%3E%3C/svg%3E");
					}

					a {
						position: relative;
						display:inline-block;
						padding-right:30px;

						&:after {
							.fas();
							content:@fa-var-long-arrow-alt-right;
							position: absolute;
							right: 0;
							top: 14px;
							font-size: 20px;
						}
					}
				}
			}

			th {
				position: relative;
				font-weight:400;

				&:before {
					content:'';
					position: absolute;
					width: 27px;
					height: 27px;
					left: 21px;
					top: 10px;
				}
			}
			td {
				@media (min-width:@screen-md-min) {
					padding-left:0;
				}
				@media (min-width:@screen-sm-min) {
					text-align:right;
				}
			}
		}
	}

	.longDesc {
		font-size: 18px;
		line-height: 1.556;
		font-weight: 300;

		h2 {
			margin-bottom: 26px;
		}

		p {
			margin-bottom: 28px;

			&+h2 {
				margin-top: -6px;
			}
		}
	}

	.relationsWrap {
		margin-top:30px;
	}

	h2.relTitle {
		text-align:center;
	}
}
