.auth main {
	float: none!important;
	max-width: 320px;
	padding:@grid-gutter-width/2;
	margin: 50px auto 0;
	background:@color-second;
	border-radius:6px;

	#auth {
		min-height:0;
		padding: 10px 0;
		margin: 0;
	}

	h1,
	label {
		color:#fff;
	}

	.boxTitle {
		margin:0;
	}

	.boxText {
		padding-bottom:20px!important;
	}
	h1 {
		margin:10px 0 20px;
		font-size:38px;
		line-height:38px;
		text-align:center;
		border-bottom:0;
	}
	.cancel {
		margin:0 5px;
		color:#fff;
		border-color:#f0f0f0;
		transition:all .4s ease;
		&:hover {
			background-color:#000;
		}
	}
	a {
		color:#fff;
	}
	.submit {
		margin:0 5px;
		color:@color-first!important;
		background:#f0f0f0;
		border-color:#f0f0f0;
		&:hover {
			color:#fff!important;
			background:@color-first-light;
		}
	}
}
