.reset {
	ol&,
	ul& {
		padding:0;
		margin:0;
		list-style:none;

		li:before { // Remove bullets added with Font Awesome
			display:none !important;
		}
	}

	a& {
		text-decoration:none;
	}
}