#breadcrumbsWrap {
	padding: 13px 0 0 15px;

	@media (max-width:@screen-sm-max) and (min-width:@screen-sm-min) {
		padding: 21px 0 0;
	}
}

#breadcrumbs {
	margin-bottom: 10px;
	font-size:14px;
	.hidden-xs;

	@media (max-width:@screen-sm-max) and (min-width:@screen-sm-min) {
		font-size:12px;
	}

	a {
		color:@color-text-light;
	}

	.sep {
		padding: 0 4px;
		color:@color-text-light;
	}

	.last {
		color:@color-text-lighter;
	}
}
