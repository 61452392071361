.quote {
	padding: 20px 15px;
	margin: 40px 0 30px 30px;
	background: @color-gray;
	border-left: 10px solid @color-border-dark;

	.fa-before(@fa-var-quote-left);
	&:before {
		.fas();
		display: block;
		margin-top: -35px;
		margin-bottom: 10px;
		font-size: 2em;
		color: @color-border-dark;
		text-transform: uppercase;
	}
}
