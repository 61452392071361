.box_extras_articles {
	.boxText {
		padding-bottom: 41px;
		border-bottom: 1px solid @color-border;

		@media (max-width:@screen-sm-max) and (min-width:@screen-sm-min) {
			padding-bottom: 9px;
		}
	}
}
