body.notfound {
	#page {
		display: flex;
		flex-direction: column;
		width: 100%;
		min-height: 100vh;
	}

	#breadcrumbs {
		display: none;
	}

	main {
		padding-top: 2%;

		h1 {
			display: none;
		}

		.boxText {
			margin:0 auto !important;
			.make-row;
		}
	}

	.error {

	}

	.error__title {
		margin-bottom: @line-height-computed;
		font-size: 50px;
		font-weight: 700;
		line-height: 1;
		color: @color-first;
		text-align: center;
		.make-xs-column(12);
		.make-sm-column(5);
	}

	.error__code {
		display: block;
		font-size: 220px;

		@media (max-width: @screen-sm-max){
			font-size: 150px;
		}

		@media (max-width: @screen-xs-max){
			font-size: 120px;
		}
	}

	.error__message {
		display: none;
	}

	.error__notice {
		.make-xs-column(12);
		.make-sm-column(7);
		margin-bottom: @line-height-computed;
		font-size: 22px;

		@media (max-width: @screen-sm-max) {
			font-size: 18px;
		}

		@media (max-width: @screen-xs-max) {
			font-size: 16px;
		}

		@media (min-width: @screen-sm-min) {
			padding-left: 30px;
		}

		@media (max-width: @screen-xs-max) {
			text-align: center;
		}

		h3 {
			font-size: 35px;
			font-weight: 700;
			color: @color-first;

			@media (max-width: @screen-sm-max) {
				font-size: 28px;
			}

			@media (max-width: @screen-xs-max) {
				font-size: 24px;
			}
		}

		ul {
			padding:0;
			margin:0;
			list-style: none;
		}

		li {
			padding-left: 1em;
			margin-bottom: 10px;
			text-indent: -.7em;
		}

		li:before {
			content: "• ";
			color: @color-first;
		}
	}

	.error__buttons {
		display: flex;
		clear:both;
		justify-content: space-around;
		padding: 30px 0;
		border-top: 1px solid @color-first;
		border-bottom: 1px solid @color-first;

		@media (max-width: @screen-xs-max) {
			align-items: center;
			flex-direction: column;
		}

		a {
			color: @link-color;

			@media (max-width: @screen-xs-max) {
				margin-bottom: 15px;
			}
			&:hover {
				color: @link-hover-color;
			}
		}

		.fa {
			margin-right: 10px;
			font-size:40px;
			color: @color-first;
			vertical-align:middle;
		}
	}
}
