#employees {
	.clearfix();

	//Hide MSN field
	#employee_tabs {
		#employee_email + br,
		label[for=employee_msn],
		#employee_msn {
			display:none;
		}
	}

	.toolbar {
		margin-top:-1px;
		border:1px solid transparent;
		border-bottom:1px solid @color-border;

		li {
			padding: 8px 0 7px;

			@media (max-width:@screen-xs-max) {
				float:none;
				width:100%!important;

				&.first {
					padding-bottom:0;
					padding-left:0;
				}
				&.middle {
					padding-top:0;
				}
				&.last {
					display:none;
				}
			}

			&.first {
				a {
					color:@color-text;
					text-decoration:none;
				}
			}
			&.middle {
				width: 38%;
			}
			&.last {
				overflow:hidden;
				width: 22%;
				padding-right: 12px;
				text-align:right;
			}
		}
		&.open {
			padding:0;
			background:@color-second;
			border:1px solid @color-second;
			outline: none;

			li, a {
				color:#fff!important;
			}
			.first {
				padding-left:20px;
			}
			.middle {
				@media (max-width:@screen-xs-max) {
					padding-left:20px;
				}
			}
		}
	}

	.staff .content {
		overflow: hidden;
		padding:20px;
		background: #fff;
		border:1px solid @color-border;
		border-top:0;

		.actions {
			margin-top:0;
		}

		.image {
			width: auto;
			margin-right: 24px;

			@media (max-width:@screen-xs-min) {
				float:none;
				margin: 0 0 10px;
			}

			img {
				max-width:150px;
				height:auto;
			}
		}

		ul.extra {
			margin-bottom: 15px;
			.clearfix();

			li {
				display:block;
				float:left;
				margin:0 15px 5px 0;

				@media (max-width:@screen-xs-max) {
					float:none;
					margin:0 0 5px;
				}

				&.phone {
					&:before {
						.fas();
						content:@fa-var-phone;
					}
				}
				&.mobile {
					&:before {
						.far();
						content:@fa-var-mobile;
					}
				}
				&.email {
					&:before {
						.fas();
						content:@fa-var-envelope;
					}
				}
				&:before {
					padding-right:8px;
				}
			}
		}

		.notes {
			color: @color-text-light;
		}
	}
}
