#store {
	.menu.list {
		.make-row();
		display:flex;
		flex-wrap:wrap;
		justify-content: center;

		> li {
			.make-xxs-column(12);
			.make-xs-column(6);
			.make-sm-column(4);
			.make-md-column(3);
			.make-lg-column(2.4);
			flex: 0 1 auto;
			display: flex;
			margin-bottom:30px;

			&.noimage {
				.wrap {
					padding-top: 90px;
				}
			}

			.wrap {
				position: relative;
				border:1px solid @color-border;
				background:@color-white;
				transition:all .4s ease;
				text-align:center;
				width:100%;
				padding:10px 15px 32px;

				&:hover {
					border-color:@color-first;
					box-shadow:0 0 4px 0 fade(@color-black,20%);

					.moreLabel {
						opacity:1;
					}
				}
			}

			.image {
				margin-bottom: 22px;

				.vendorView & {
					padding: 0 24px;
					margin-bottom: 10px;
				}
			}

			.content {
				position: relative;

				.vendorView & {
					font-size: 20px;
					font-weight: 300;
					line-height: 1.4;
				}
			}

			.title {
				font-size:18px;
				font-weight: 600;

				.vendorView & {
					font-size: 24px;
					margin-bottom:0;
				}

				a {
					color:@color-black;

					&:after {
						content:'';
						position: absolute;
						left:0;
						right:0;
						top:0;
						bottom:0;
					}
				}
			}

			.moreLabel {
				position: absolute;
				left: 13px;
				right: 13px;
				top: -66px;
				padding: 3px 10px 5px;
				border-radius: 4px;
				background:@color-first;
				color:@color-white;
				opacity:0;
				transition:opacity .4s ease;

				&:before {
					content:attr(data-details);
				}

				.vendorView & {
					position: relative;
					opacity: 1;
					left: auto;
					right: auto;
					top: auto;
					max-width: 212px;
					margin:47px auto 5px;
					padding: 7px 10px 9px;
				}
			}

			.more {
				position: absolute;
				left:0;
				right:0;
				top:0;
				bottom:0;
			}
		}
	}

	.categoryProducts {
		> p {
			text-align:center;
		}
	}
}
