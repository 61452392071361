#adman {
	#elm_preview {
		.pull-right();
		.make-md-column(4);
	}

	#elm_file {
		clear: both;
	}

	#elm_region_id select[disabled] {
		background: #eee;
	}

	.sortable.horizontal {
		.clearfix();
		li {
			float:left;
			display:block;

			.image {
				height:80px;
				width:80px;
			}
		}
	}

	#sortList {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		padding-left: 4px;

		li {
			flex-grow: 0;
			float: none;
			width: calc(~"20% - 6px");
			margin: 0 0 6px 6px;
			background: #f9f9f9;
			border: 1px solid #ccc;
			padding: 8px;
			text-align: center;

			.image {
				width: 100%;
				padding-bottom: 50%;
				background-size:contain;
				background-position:center;
				background-repeat: no-repeat;
			}

			.text {
				margin-top: 4px;
			}
		}
	}

	fieldset {
		border: 0;
		padding-left: 0;
		padding-right: 0;
	}

	/*adverts*/
	.preview,
	#tab1 > img {
		float:right;
		margin:10px;
		max-width:320px;
		max-height:320px;
		height: auto;
	}
	.preview img{
		max-width: 100%!important;
		height: auto!important;
		max-height: 190px;
	}
}

#imageForm {
	&.region-selected {
		.elm 	{
			display:block;
		}

		.cancel,
		.submit {
			display:inline-block;
		}
	}
	.elm,
	.cancel,
	.submit {
		display:none;
	}

	#elm_region_id {
		display:block;
	}
}
