footer{
	position: relative;
	flex-shrink: 0;
	margin-bottom: -2%;
	color:@color-white;
	padding:193px 0 30px;
	background:@color-black url('@{path-images}footer.jpg') no-repeat  center top;
	background-size: cover;
	letter-spacing: -0.2px;

	//IE fix
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		min-height:8vh;
	}

	@media (max-width:@screen-sm-max) {
		padding: 69px 9px 0;
	}

	&:before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		#gradient > .vertical(fade(@color-white, 100%), fade(@color-white, 0));
	}

	&:after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 193px;
		bottom:0;
		background:fade(#160f0b, 90%);

		@media (max-width:@screen-sm-max) {
			top: 68px;
		}
	}

	> .contentWrap {
		.clearfix();
		z-index: 1;
		padding: 65px 0 0;
		display: flex;
		flex-wrap: wrap;

		@media (max-width:@screen-xs-max) {
			padding: 38px 5px 0;
		}
	}

	a {
		color: @color-white;

		&:hover {
			color: @color-first;
		}

		&[href*="maps"] {
			font-size:18px;
		}
	}

	.box {
		.make-xs-column(12);
		.make-sm-column(6);
		.make-md-column(3);
		margin-bottom:30px;

		@media (max-width:@screen-sm-max) {
			margin-bottom:75px;
		}
		@media (max-width:@screen-xs-max) {
			margin-bottom:40px;
		}

		&:nth-child(2) {
			.make-md-column(4);
		}
		&:nth-child(4) {
			.make-md-column(2);
		}

		.boxTitle {
			h2 {
				font-size: 22px;
				color: #fff;
				font-weight: 600;
				margin-bottom: 26px;
			}
		}

		.boxText {
			line-height: 32px;
		}

		p {
			margin-bottom: 24px;

			&:last-child {
				margin-bottom:0;
			}
		}

		a[href*="maps"],
		&[class*="page"] li a span {
			position: relative;
			padding-right:40px;

			&:after {
				.fas;
				content:@fa-var-long-arrow-alt-right;
				position: absolute;
				right:0;
				bottom: 0;
				font-size:20px;
				color:@color-first;
			}
		}

		&[class*="page"] {
			font-size: 18px;

			.boxText:first-child {
				@media (min-width:@screen-md-min) {
					padding-top: 60px;
					line-height: 21px;
				}
			}

			ul {
				&:extend(ul.reset);
			}

			li {
				a {
					position: relative;
					display: inline-block;
					padding: 2px 0;

					span {
						.ellipsis(1,18px,1.4445);
					}
				}
			}
		}

		&.box_social {
			font-size: 18px;

			.boxText {
				@media (min-width:@screen-md-min) {
					padding-left: 32px;
				}

				&:first-child {
					@media (min-width:@screen-md-min) {
						padding-top: 68px;
					}
				}
			}

			ul {
				&:extend(ul.reset);

				li {
					margin-bottom:12px;

					a {
						position: relative;
						display:inline-block;
						padding:3px 0 3px 57px;

						&:before {
							position: absolute;
							left:0;
							top:0;
							width:40px;
							height:40px;
							border:1px solid;
							border-radius:50%;
							text-align:center;
							font-size:20px!important;
							line-height:40px!important;
						}

						&[href*="facebook"]:before {
							content:@fa-var-facebook-f;
							.fab();
						}

						&[href*="mailing"]:before {
							content:@fa-var-envelope;
							.fas();
						}
					}
				}
			}
		}

		&.box_quality {
			.boxText:first-child {
				@media (min-width:@screen-md-min) {
					padding-top: 65px;
				}
			}
		}

		.jip.list {
			position: absolute;
			right: 15px;
			top: 9px;
			width: 16px;
			height: 16px;
			overflow: hidden;
			background: none;
			border: 0;
			margin: 0;

			a {
				margin: 0;
				padding: 0;
				line-height: 1;
			}
		}
	}
}
