header {
	body:not(.frontpage) & {
		@media (min-width:@screen-md-min) {
			margin-bottom: 35px;
		}
	}

	> .contentWrap {
		@media (min-width:@screen-md-min) {
			display: flex;
		}
		@media (max-width:@screen-sm-max) and (min-width:@screen-sm-min) {
			padding-left: 48px;
			padding-right: 48px;
		}
	}

	.logo {
		position: relative;
		z-index: @zindex-header-logo;

		@media (min-width:@screen-md-min) {
			flex: 0 0 auto;
			width: 108px;
			padding-right: 15px;
		}

		// Mobile header
		@media (max-width:@screen-sm-max) {
			position:fixed;
			z-index:@zindex-header;
			top:0;
			left:0;
			width:100%;
			height:@header-height-mobile;
			background:@color-white;
			border-bottom:1px solid #ccc;

			body.loggedIn & {
				top:31px;
			}
		}

		a {
			display:inline-block;
			@media (min-width:@screen-md-min) {
				margin-left:-20px;
			}
			@media (max-width:@screen-sm-max) {
				padding: 5px;
			}

			img {
				max-width:none;
				height:139px;

				@media (max-width:@screen-sm-max) {
					height: 72px;
				}

				@media (max-width: @screen-xs-max) {
					transition: opacity .4s ease;
					body.mainmenu-open & {
						opacity: 0;
					}
				}
			}
		}
	}
}

#qn {
	padding: 41px 0 0 10px;
	font-size: 22px;
	@media (max-width:@screen-md-max) and (min-width:@screen-md-min) {
		font-size:18px;
		padding-left:0;
	}
	@media (max-width: @screen-sm-max) {
		position: fixed;
		top:0;
		right:75px;
		padding: 12px 0 11px;
		z-index: 10;
		font-size:15px;
	}
	@media (max-width: @screen-xs-max) {
		transition: opacity .4s ease, transform .4s ease;
		body:not(.mainmenu-open) & {
			opacity: 0;
			pointer-events: none;
			transform: translateX(320px);
		}
	}

	ul {
		display:flex;

		li {
			&:not(:last-child) {
				margin-right:60px;

				@media (max-width:@screen-md-max) {
					margin-right:15px;
				}
				@media (max-width: @screen-sm-max) {
					margin-right:25px;
				}
			}
		}

		a {
			position: relative;
			display:inline-block;
			color:@color-second;
			padding-left:45px;

			@media (max-width: @screen-sm-max) {
				padding-left:29px;
				font-weight: 600;
				font-size: 14px;
			}

			svg {
				position: absolute;
				left: 0;
				width: 29px;
				top: 4px;

				@media (max-width: @screen-sm-max) {
					width:19px;
				}

				path {
					transition:fill .4s ease;
				}
			}

			&:hover {
				color:@color-first;

				svg path {
					fill: @color-first;
				}
			}

			&[href^="tel"] {
				padding-left:30px;
				white-space: nowrap;

				@media (max-width: @screen-sm-max) {
					padding-left: 20px;
					margin-left: 13px;
				}

				svg {
					width: 22px;
					top: 6px;

					@media (max-width: @screen-sm-max) {
						width:15px;
						height:18px;
					}
				}
			}

			span {
				@media (max-width: @screen-sm-max) {
					line-height: 1;
				}
			}
		}
	}
}
