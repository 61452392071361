@menuBg: @body-bg;
@borderColor: #ccc;
@mmFontfamily:@font-family-sans-serif;

@mmIndent: 35px;

// Level 1
@level-1-color: @color-text-dark;
@level-1-font-weight: 700;
@level-1-font-size: 18px;
@level-1-bg-color: transparent;
@level-1-selected-color: @color-first;
@level-1-selected-bg-color: none;
@level-1-text-transform: none;

// Level 2
@level-2-color: @color-text;
@level-2-font-weight: 300;
@level-2-font-size: 16px;
@level-2-bg-color: transparent;
@level-2-selected-color: @color-first;
@level-2-selected-bg-color: none;
@level-2-text-transform: none;

//Level 3
@level-3-color: @color-text;
@level-3-bg-color: transparent;
@level-3-font-weight: 300;

//Level 4
@level-4-color: @color-text;
@level-4-bg-color: transparent;
@level-4-font-weight: 300;

#page {
	&:after {
		content:'';
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		max-width:0;
		opacity: 0;
		z-index: @zindex-header - 1;
		background: rgba(0,0,0, 0.3);
		transition: .4s ease all;
		transform: translateZ(0);
	}

	.mainmenu-open & {
		&:after {
			opacity: 1;
			max-width: 9999em;
		}
	}
}

body {
	&.mainmenu-open {
		overflow:hidden;
	}
}

#mainmenu {
	@media (max-width:@screen-sm-max) and (min-width:@screen-xs-min) {
		max-width: 320px;
	}
	@media (max-width:@screen-sm-max) {
		position: fixed;
		top: calc(~'@{header-height-mobile} - 1px');
		right: 0;
		bottom: 0;
		z-index: @zindex-header + 1;
		width: 100%;
		background: @menuBg;
		overflow-y: auto;
		transform:translateX(100%);
		transition: transform .4s ease;

		body.mainmenu-open & {
			transform:translateX(0);
		}
		body:not(.mainmenu-open):not(.mainmenu-closing) & {
			opacity:0;
		}
		.loggedIn & {
			margin-top: 31px;
		}

		li {
			position: relative;

			&:not(.down) {
				> ul {
					max-height: 0!important;
					overflow: hidden;
				}
			}

			> ul {
				transition: max-height .4s ease;
			}
			&:hover {
				color: @color-first;
			}
			&[class*="selectedLevel"] > a {
				color: @color-first;
				font-weight: 700;
			}
		}

		a {
			display:block;
			font-family:@mmFontfamily;
		}

		.mmLevel1 {
			display:block;
			border-bottom: 1px solid @borderColor;
		}

		.mmLevel2 {
			position: relative;
			overflow: hidden;
			background: @level-2-bg-color;

			> li.down {
			}

			> li {
				&.down {
					background:@level-2-selected-bg-color;
				}
				&:last-child {
					padding-bottom: 20px;
				}
			}
		}

		.mmLevel3 {
			background:@level-3-bg-color;
		}

		.mmLevel4 {
			background:@level-4-bg-color;
		}

		.level1 {
			display:block;
			width:100%;
			position: relative;
			border-top:1px solid @borderColor;

			> a {
				text-transform: @level-1-text-transform;
				color:@level-1-color;
				font-weight: @level-1-font-weight;
				font-size:@level-1-font-size;
				padding:9px 20px 9px;
			}

			> .expand {
				padding:16px 15px;
			}
		}

		.level2 {
			font-size:16px;

			> a {
				color: @level-2-color;
				font-weight:@level-2-font-weight;
				font-size: @level-2-font-size;
				padding: 0px 0 0px 6px + @mmIndent;
			}

			> .expand {
				padding: 0 15px;
			}
		}

		.level3 {
			a {
				color: @level-3-color;
				padding:0px 15px 0px 15px + @mmIndent;
				font-weight: @level-3-font-weight;
			}

			.expand {
				padding: 0 15px;
			}
		}

		.level4 {
			a {
				color: @level-4-color;
				font-weight: @level-4-font-weight;
			}
			> a {
				padding-left: 15px + @mmIndent * 2;
			}
		}

		.level5 {
			> a {
				padding-left: 15px + @mmIndent * 3;
			}
		}

		.mmLevel6 {
			display:none;
		}

		.selectedLevel1  {
			.expand  {
				i {
					color: @level-1-selected-color;
				}
			}

			> a {
				background:@level-1-selected-bg-color;
				color: @level-1-selected-color;
			}
		}

		.expand {
			display:block!important;
			position:absolute;
			top:0;
			right:0;
			font-size: 18px!important;
			line-height: 1;
			overflow:hidden;
			cursor: pointer;
			color: @color-first;

			i {
				transition:transform .4s ease;
			}
		}

		.down > .expand i {
			transform:scale(-1);
		}
	}
}
