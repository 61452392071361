a {
	transition: color .4s ease;
}

img.responsive {
	.img-responsive();

	visibility:hidden;

	&.loaded {
		visibility:visible;
	}
}

img {
	max-width:100%;
	height:auto;
}

.jip img,
.toolCol img,
img.icon {
	max-width:none;
}

.entry {
	margin-top: 0;
}

h1, h2, h3, h4, h5, h6 {
	&:first-child {
		margin-top: 0;
	}
}

h1 {
	font-size: 40px;
	font-family:@font-family-condensed;
	font-weight: 600;
	margin-bottom: 47px;

	.extras &,
	.store & {
		text-transform:uppercase;
	}

	.auth &,
	.extras.view &,
	.store.category &,
	.store.products & {
		text-align:center;
	}

	.extras.view & {
		margin-bottom: 8px;
	}

	.store.productView & {
		letter-spacing: -0.4px;

		@media (min-width:@screen-md-min) {
			font-size:36px;
		}
		@media (max-width:@screen-xs-max) {
			font-size: 26px;
			margin-top: -6px;
		}
	}

	@media (max-width:@screen-sm-max) and (min-width:@screen-sm-min) {
		margin-bottom: 36px;
	}
	@media (max-width:@screen-xs-max) {
		font-size: 22px;
		margin-bottom: 30px;
	}
	@media print {
		margin: 0 -15px 20px;
	}
}

#tinymce,
.entryContent {
	font-weight:300;

	@media (max-width:@screen-xs-max) {
		font-size:18px;
	}

	h2 {
		margin-bottom: 38px;
	}
	h2, h3, h4 {
		clear:both;

		&:not(:first-child) {
			margin-top:@default-margin * 2;
		}
	}
	p,
	ul,
	ol {
		margin-bottom: @default-margin;

		&:last-child {
			margin-bottom:0;
		}
	}

	ul {
		.arrowList();
		margin-left: 10px;
	}

	ol {
		padding-left: 15px;
		margin-left: 10px;
		list-style-type: none;

		li {
			margin-bottom:5px;
		}

		> li {
			counter-increment: count;

			&:before {
				content: counter(count) ". ";
				display: inline-block;
				min-width: 30px;
				padding-right: 5px;
				margin-left: -30px;
				color: @color-first;
				text-align: right;
			}
		}

		ol {
			list-style-type: lower-alpha;

			li {
				margin:5px 0;
				counter-increment:none;
				&:before {
					display:none;
				}
			}
		}

	}

	hr {
		margin: 49px 0 41px;
	}

	img {
		&.special {
			margin-top: -3px;
			margin-right:50px;

			&[style*="float"] {
				@media (max-width:@screen-xxs-max) {
					float:none!important;
				}
			}
		}
	}

	p {
		&.smaller {
			font-size: 18px;
			line-height: 1.944;
			overflow: hidden;
		}
		&.larger {
			font-size: 22px;
		}
	}
}
