body.search {
	h1 {
		span {
			color: @color-first;
		}
	}
}

#search {
	min-height: 300px;

	#elm_t {
		display: block;
	}

	.boxText {
		position: relative;

		#searchForm {
			width: 100%;

			#elm_t {
				margin: 30px 0 15px;

				@media (max-width: @screen-xs-max) {
					margin: 30px 0 20px;
				}

				body.searchRight & {
					@media (min-width: @screen-sm-min) {
						margin: 0;
					}

					@media (min-width: @screen-sm-min) {
						position: absolute;
						top: 0;
						right: 0;
						width: 24%;
					}
				}

				> label {
					display: none;
					margin-bottom: 35px;
					font-weight: @headings-font-weight;

					body.searchRight & {
						&:extend(aside .boxTitle h2 all);
						&:extend(aside .box .boxTitle h2 all);
						&:extend(aside .boxText .mmLevel2 .level2 > a all);
						display: block;
						color: inherit;
					}
				}

				.lmnt {
					body:not(.searchRight) & {
						display: flex;
						flex-wrap: wrap;

						@media (max-width: @screen-sm-max) {
							justify-content: center;
						}
					}

					label {
						padding-bottom: 11px;
						margin-right: 20px;
						margin-bottom: 10px;

						body.searchRight & {
							position: relative;
							padding-left: 33px;
							margin-right: 0;
							border-bottom: 1px solid #c9c9c9;
						}

						input[type=checkbox] {
							position: relative;
							top: 1px;
							width: 18px;
							height: 18px;
							margin: 0 15px 0 0;

							body.searchRight & {
								position: absolute;
								left: 0;
							}

							&:checked,
							&:hover {
								&:before {
									background: @color-first;
								}
							}

							&:before {
								content: '';

								position: absolute;
								top: 0;
								right: 0;
								bottom: 0;
								left: 0;
								width: 18px;
								height: 18px;
								pointer-events: none;
								background: @color-white;
								border: 1px solid #b7b7b7;
								border-radius: 4px;
								transition: 0.4s ease all;
							}
						}
					}
				}
			}

			#elm_q {
				input {
					padding-left: 15px;
					color: @color-black;

					body.searchRight & {
						@media (min-width: @screen-sm-min) {
							width: 70%;
						}
					}
				}
			}

			#elm_submit {
				body.searchRight & {
					@media (min-width: @screen-sm-min) {
						right: 30%;
					}
				}

				&:before {
					right: 5px;
				}
			}
		}

		.resultsText {
			padding: 17px 0 30px;

			body.searchRight & {
				width: 70%;

				@media (max-width: @screen-xs-max) {
					width: 100%;
				}
			}
		}

		.term {
			float: none;

			body.searchRight & {
				width: 70%;

				@media (max-width: @screen-xs-max) {
					width: 100%;
				}
			}
		}

		.searchResults {
			body.searchRight & {
				@media (min-width: @screen-sm-min) {
					width: 70%;
				}
			}

			ol.items {
				li {
					padding: 20px 30px 30px;
					border: 1px solid #ccc;
					border-radius: 4px;
					transition: 0.4s ease all;

					@media (max-width: @screen-xs-max) {
						padding: 20px 15px 30px;
					}

					&:hover {
						border: 1px solid @color-first;
						box-shadow: 0 0px 53px -9px rgba(0, 0, 0, 0.3);
					}

					.title {
						a {
							font-size: 20px;
							font-weight: 600;
							color: @color-text;

							&:hover {
								color: @color-first;
							}
						}
					}

					.content {
						color: @color-text-light;

						em {
							color: @color-first;
						}
					}

					.img {
						float: right;
						margin-right: 0;
						margin-bottom: 20px;
						margin-left: @grid-gutter-width / 2;
					}

					.type {
						margin-bottom: 10px;
						color: @color-text-light;

						&:before {

							margin-right: 10px;
							color: @color-first;
							.fas();
						}

						&.employees {
							&:before {
								content: @fa-var-user;
							}
						}

						&.page {
							&:before {
								content: @fa-var-bookmark;
							}
						}

						&.news {
							&:before {
								content: @fa-var-newspaper;
							}
						}

						&.gallery {
							&:before {
								content: @fa-var-image;
							}
						}

						&.extras {
							&:before {
								content: @fa-var-paperclip;
							}
						}
					}
				}
			}
		}
	}
}
