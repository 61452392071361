#mainmenuToggle {
	position:fixed;
	z-index:98;
	top: 0;
	right: 0;
	width: @header-height-mobile;
	height: @header-height-mobile;
	padding: 13px 0;
	cursor: pointer;
	background: none;
	border: 0;
	.visible-xs-block();
	.visible-sm-block();

	.loggedIn & {
		top:31px;
	}

	span {
		display: block;
		width: @header-height-mobile / 2;
		height: 3px;
		margin: 4px auto;
		background: @color-second;
		transition: transform .4s ease, opacity .4s ease, background .4s ease;

		.mainmenu-open & {
			&.first {
				transform: translateY(8px) rotate(45deg);
				background: @color-first;
			}
			&.middle {
				opacity:0;
			}
			&.last {
				transform: translateY(-6px) rotate(-45deg);
				background: @color-first;
			}
		}
	}
}

.menuWrap {
	@media (min-width:@screen-md-min) {
		flex: 1 1 auto;
	}
}

#mainmenu {
	@media (min-width:@screen-md-min) {
		.expand {
			display: none;
		}
	}

	.mmLevel1 {
		@media (min-width:@screen-md-min) {
			display: flex;

			> li {
				position: relative;

				&:not(:last-child) {
					margin-right:9px;

					> a span:after {
						content:'';
						position: absolute;
						right: -5px;
						top: 9px;
						height: 18px;
						border-right:1px solid @color-black;
					}
				}

				> a {
					display:inline-block;
					padding: 46px 0 25px;
					color: @color-black;
					text-align: center;
					font-size: 18px;
					font-weight: 300;
					line-height: 1.2223;

					span {
						position: relative;
						display:block;
						padding:5px 15px 0;

						@media (max-width:@screen-md-max) and (min-width:@screen-md-min) {
							padding-left:10px;
							padding-right:10px;
						}
					}
				}

				> a:hover {
					color:@color-first;
				}
				&.selectedLevel1 > a {
					color:@color-first;
					font-weight:bold;
				}

				// Default behavior is to show only first level in mainmenu
				> ul {
					display:none;
				}
			}
		}
	}
}
