@accordion-padding-left: 10px;
@accordion-padding-right: 53px;

.accordion {
	> *:first-child {
		&:before {
			left: auto;
			right: 16px;

			@media (max-width:@screen-xs-max) {
				right:6px;
			}
		}
		&:after {
			left: auto;
			right: 16px;

			@media (max-width:@screen-xs-max) {
				right:6px;
			}
		}
	}
}
