.storeCategoryFilter {
	font-size:16px;
	text-align:center;
	margin: -9px 0 42px;

	a {
		position:relative;
		display: inline-block;
		padding-left: 17px;
		padding-right: 10px;
		color:@color-text-dark;
		margin-bottom: 5px;

		&:before,
		&:after {
			position: absolute;
			top: 7px;
			width: 1px;
			height: 17px;
			margin:auto;
			background:@color-text-dark;
		}

		&:before {
			content:'';
			left:0;
		}

		&:last-child {
			&:after {
				content:'';
				right:0;
			}
		}

		&.selected {
			color:@link-color;
			font-weight:bold;
		}
		&:hover {
			color:@link-color;
		}

	}
}
