@accordion-padding-top: 15px;
@accordion-padding-right: 10px;
@accordion-padding-bottom: 14px;
@accordion-padding-left: 53px;

.accordion {
	position: relative;
	border-top: 1px solid @color-border;
	border-bottom: 1px solid @color-border;
	margin-bottom: -1px;
	.clearfix();

	&:first-of-type {
		margin-top: 38px;
	}
	&:last-of-type {
		margin-bottom: 53px;
	}
	&:not(:first-of-type) {
		@media print {
			padding-top: 15px;
		}
	}

	> *:first-child {
		font-size: 20px;
		font-weight: @font-weight-bold;
		margin: 0!important;
		color: @color-text-dark;
		line-height:1;

		a {
			display: block;
			color: inherit!important;
		}

		&:before,
		&:after {
			content: '';
			position: absolute;
			width: 16px;
			height: 2px;
			background: @color-first;
			transition: transform .4s ease;
			pointer-events: none;
			.noprint;
		}

		&:before {
			left: 16px;
			top: 23px;

			@media (max-width:@screen-xs-max) {
				left:6px;
			}
		}
		&:after {
			left: 16px;
			top: 23px;
			transform: rotate(90deg);

			@media (max-width:@screen-xs-max) {
				left:6px;
			}
		}

		.open& {
			&:before {
				@media only screen {
					transform: rotate(225deg);
				}
			}
			&:after {
				transform: rotate(315deg);
			}
		}
	}
	&:not(.ready) > *:first-child {
		position: relative;
	}
	&:not(.ready) > *:first-child,
	&.ready > *:first-child a {
		padding: @accordion-padding-top @accordion-padding-right @accordion-padding-bottom @accordion-padding-left;

		@media (max-width:@screen-xs-max) {
			padding-left:@accordion-padding-left - 10px;
		}
		@media print {
			padding-left:0;
			text-decoration: none;
		}
	}
	>*:not(:first-child):not(.wrap) {
		@media only screen {
			padding-left: @accordion-padding-left;
			padding-right: @accordion-padding-right;
		}
		@media only screen and (max-width:@screen-xs-max) {
			padding-left:@accordion-padding-left - 10px;
			padding-right:@accordion-padding-right - 10px;
		}

		body:not(#tinymce) & {
			display: none;
		}
	}

	.wrap {
		@media only screen {
			overflow: hidden;
			padding-left: @accordion-padding-left;
			padding-right: @accordion-padding-right;
		}
		@media only screen and (max-width:@screen-xs-max) {
			padding-left:@accordion-padding-left - 10px;
			padding-right:@accordion-padding-right - 10px;
		}
	}

	&:not(.open) {
		cursor: pointer;
	}

	&.ready .wrap {
		transition: max-height .4s ease;

		@media print {
			max-height:none!important;
		}

		> *:last-child {
			margin-bottom: 20px;
		}
	}
	&:not(.open) {
		.wrap {
			@media only screen {
				max-height: 0 !important;
			}
		}
	}
}
