#searchToggle {
	position: relative;
	border: 0;
	margin-right: -5px;
	background: none;
	font-size:20px;
	color:@color-first;
	padding: 0 10px;
	display: inline-block;
	text-align:center;
	transition:color .4s ease;

	@media (max-width:@screen-sm-max) {
		position: absolute;
		padding: 15px 0;
	}

	.search-open &,
	.search-closing & {
		color:@color-first;
	}

	i {
		transition:transform .4s ease;

		.search-open & {
			transform:perspective(1px) rotate(360deg);
		}
	}
}

#searchBox {
	@media (min-width:@screen-md-min) {
		position: absolute;
		top: 0;
		right: 15px;

		form {
			position:absolute;
			right: 0;
			top: 36px;
			overflow:hidden;
			transition:width .4s ease;

			body:not(.search-open) & {
				width:0!important;
			}

			.search-open & {
				width:350px;
			}

			&:before {
				content:'';
				position: absolute;
				left:0;
				right:0;
				top:0;
				bottom:0;
				border: 1px solid @color-first;
				background: @color-white;
			}
		}

		.elm_text {
			position: relative;
			float:right;
			padding: 1px;
		}

		.elm {
			margin:0;
		}

		.elm_text {
			width:100%;
		}

		.lmnt {
			display:block;
		}

		#q {
			width:100%;
			border: 0;
			outline:0;
			.placeholder(@color-text-light);
			color:@color-text-light;
			height:auto;
			box-shadow: none;
			padding: 10px 50px 8px 10px;
			border-radius:0;
			min-width: 0;
		}

		.elm_submit {
			position: absolute;
			top: 1px;
			bottom: 1px;
			right: 1px;
			width: 48px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;

			&:before {
				.fas();
				content: @fa-var-search;
				position: absolute;
				font-size:20px;
				color:@color-first;
				transition:color .4s ease;
			}

			&:hover:before {
				color:@color-second;
			}
		}

		#searchSubmit {
			position: relative;
			text-indent: 99px;
			overflow: hidden;
			background: none;
			border: 0;
			width:58px;
			height:58px;
		}
	}

	@media (max-width:@screen-sm-max) {
		position: absolute;
		top: 0;
		width: 100%;
		padding: 0 15px;
		display:none;

		form {
			position: relative;
			margin-top: 10px;
			transition:opacity .4s ease;

			body:not(.search-open) & {
				opacity:0;
				visibility:hidden;
			}
		}

		#q {
			width: 100%;
			padding-right: 40px;
		}

		#elm_submit {
			position: absolute;
			top: 0;
			right: 0;
			margin: 0;
			height: 100%;

			&:before {
				.fas();
				content:@fa-var-search;
				display:block;
				font-size: 22px;
				color: @color-first;
				padding:8px;
				transition: color .4s ease;

				body.searchbox-open & {
					color:@color-second;
				}
			}
		}
		#searchSubmit {
			position: absolute;
			width: 100%;
			height: 100%;
			border: 0;
			padding: 0;
			margin: 0;
			cursor: pointer;
			text-indent: -9999em;
			font-size: 0;
			background: none;
			top: 0;
			right: 0;
			line-height: 0;
			color:@color-first;
		}
	}
}
