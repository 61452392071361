#extras {
	.category.detailed {
		.items {
			.make-row();
			display:flex;
			flex-wrap:wrap;

			@media(max-width: @screen-xs-max) {
				justify-content: center;
			}

			.item {
				.make-xs-column(12);
				.make-sm-column(6);
				.make-md-column(6);
				.make-lg-column(4);
				max-width: 480px + @grid-gutter-width;
				margin-bottom: 53px;

				.wrap {
					position: relative;

					&:hover .image img {
						transform: perspective(1px) scale(1.04);
					}
				}

				.image {
					border: 1px solid @color-border;
					overflow:hidden;
					margin-bottom: 7px;

					img {
						width: 100%;
						transition: transform .4s ease;
					}

				}

				.date {
					font-size: 16px;
					color: @color-text-light;
					margin-bottom: 3px;
				}

				h2 {
					margin:0;
					font-family: @font-family-condensed;
					font-weight:600;
					font-size: 30px;
					letter-spacing: -0.2px;
					line-height: 34px;
					margin-bottom:7px;

					@media (max-width:@screen-xs-max) {
						font-size: 24px;
					}

					a {
						display:block;
						color:@color-black;

						&:hover {
							color:@color-first;
						}

						&:after {
							content:'';
							position: absolute;
							left:0;
							right:0;
							top:0;
							bottom:0;
						}
					}
				}

				.intro {
					line-height:1.4;
					font-weight:300;
					color: @color-black;

					@media (max-width:@screen-xs-max) {
						font-size: 18px;
						line-height: 1.4445;
					}

					i {
						position: relative;

						&:before {
							position: absolute;
							bottom: -5px;
							color:@color-first;
						}
					}
				}

				.jip {
					position: absolute;
					right: 5px;
					top: 0;
				}
			}
		}
	}
}
