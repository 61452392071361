#extras {
	&.view {
		.item {
			.date {
				text-align: center;
				font-weight: 300;
				font-size: 16px;
				margin-bottom: 53px;

				@media (max-width:@screen-xs-max) {
					margin-bottom: 30px;
				}
			}

			.contentWrap {
				max-width: 1252px;
				min-height: 100px;

				@media (max-width:@screen-sm-max) {
					padding-left:0;
					padding-right:0;
				}
			}

			.innerWrap {
				 @media (min-width:@screen-md-min) {
					 padding: 0 125px;
				 }
			}

			.image {
				text-align: center;
				margin-bottom: 40px;
			}

			.entryContent {
				h2 {
					font-size: 30px;
					margin-top: 44px;
					margin-bottom: 15px;
				}
			}

			.footer {
				@media (min-width:@screen-md-min) {
					position: absolute;
					top: 2px;
					left: 15px;
				}
				@media (max-width:@screen-sm-max) {
					.clearfix();
					margin-top: 50px;
					border-top: 1px solid @color-border;
					border-bottom: 1px solid @color-border;
					padding: 10px 0;
				}

				a.back {
					position: relative;
					display: block;
					text-transform: uppercase;
					font-weight: bold;
					font-size: 16px;
					padding: 5px 0 6px 24px;

					@media (min-width:@screen-md-min) {
						border-top: 1px solid @color-border;
						border-bottom: 1px solid @color-border;
						margin-bottom: 12px;
					}
					@media (max-width:@screen-sm-max) {
						float:left;
					}

					&:before {
						.fas();
						content:@fa-var-chevron-left;
						position: absolute;
						left: 2px;
						top: 11px;
					}
				}

				.social {
					@media (min-width:@screen-md-min) {
						display: flex;
						justify-content: space-between;
					}
					@media (max-width:@screen-sm-max) {
						float:right;
					}

					a {
						width: 40px;
						display: block;
						height: 41px;
						border: 1px solid @color-border;
						border-radius: 50%;
						margin-left: 1px;
						text-align: center;
						line-height: 40px;
						font-size:20px;
						color:#9d9d9d;
						transition:all .4s ease;

						@media (max-width:@screen-sm-max) {
							float: left;
							margin-left: 10px;
						}

						&[href*="facebook"]:before {
							.fab();
							content:@fa-var-facebook-f;
						}
						&[href*="twitter"]:before {
							.fab();
							content:@fa-var-twitter;
						}

						&:hover {
							border-color:@color-first;
							background:@color-first;
							color:@color-white;
						}
					}
				}
			}
		}
	}
}